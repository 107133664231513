import React from "react"
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import LinkedArt from '../images/linked-art.jpg'
import HorizonArt from '../images/horizon-art.jpg'
import SubterraneanArt from '../images/subterranean-art.jpg'
import ReflectArt from '../images/reflect-art.jpg'
import LesionsArt from '../images/lesions-art.jpg'

const AlbumCarousel = () => {

    const responsive = {

        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

    return (
        <div className="album-carousel">

          <Carousel showDots={true} renderDotsOutside={true} removeArrowOnDeviceType={["tablet", "mobile"]} responsive={responsive} ssr={true}>

            <div className="album-carousel-art"><a className="listen-link" href={"https://distrokid.com/hyperfollow/naideia/lesions"}><img src={LesionsArt} alt="Lesions Album Art" /></a></div>

            <div className="album-carousel-art"><a className="listen-link" href={"https://distrokid.com/hyperfollow/naideia/reflect"}><img src={ReflectArt} alt="Reflect Album Art" /></a></div>

            <div className="album-carousel-art"><a className="listen-link" href={"https://distrokid.com/hyperfollow/naideia/subterranean"}><img src={SubterraneanArt} alt="Subterranean Album Art" /></a></div>

            <div className="album-carousel-art"><a className="listen-link" href={"https://distrokid.com/hyperfollow/naideia/horizon"}><img src={HorizonArt} alt="Horizon Album Art" /></a></div>

            <div className="album-carousel-art"><a className="listen-link" href={"https://distrokid.com/hyperfollow/naideia/linked-ep"}><img src={LinkedArt} alt="Linked EP Album Art" /></a></div>
            
          </Carousel>

        </div>
    )
  }
  
  export default AlbumCarousel

  