import React from "react"
import Hero from '../images/hero-v2.jpg'
import Logo from '../images/naideia-logo-white.svg'

function renderHero() {
    return <div className="hero-div">
    
    <img className="hero-logo" src={Logo} />
    <img src={Hero} alt="Hero" className="hero-banner" />
    
    </div>;
}

export default renderHero;
