import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import Logo from "../components/logo"
import Hero from "../components/hero"
import Social from "../components/social-links"
import { Helmet } from "react-helmet"
import EmailForm from "../components/email-form"
import AlbumCarousel from "../components/carousel"
import AlbumArtVert from "../components/album-art-vert"

const IndexPage = () => (
  <Layout>
  <Helmet>
  
  <script>
      {`
        var cpm = {};
        (function(h,u,b){
        var d=h.getElementsByTagName("script")[0],e=h.createElement("script");
        e.async=true;e.src='https://cookiehub.net/c2/9410b03d.js';
        e.onload=function(){u.cookiehub.load(b);}
        d.parentNode.insertBefore(e,d);
        })(document,window,cpm);
      `}
    </script>
</Helmet>

    <SEO title="naideia" />
    <Hero />
    <div className="intro-section">

      <h4 id="releases" className="promo-title">Releases</h4>
      <hr />
    
    </div>

    <AlbumArtVert />    
    <AlbumCarousel />
    {/*
    <div className="spotify-button">
      <a className="listen-link" href={"https://open.spotify.com/artist/1LRolJj9QDXXL6olDwZlOA?si=j0vtXUehRN27gWBZUuugkw"}><button className="service-button">Follow naideia on Spotify</button></a>
    </div> */}

    <EmailForm />
    <footer>
      <Social />
      <p>Naideia is an electronic music project by multi-instrumentalist and producer, <a href={"https://linktr.ee/jamescole"}>James Cole</a>.</p>
      <p>© James Cole</p>
    </footer> 

  </Layout>
)

export default IndexPage
