import React from "react"
import MusicPlayer from "../components/music-player"
import LinkedArt from '../images/linked-art.jpg'
import HorizonArt from '../images/horizon-art.jpg'
import SubterraneanArt from '../images/subterranean-art.jpg'
import ReflectArt from '../images/reflect-art.jpg'
import LesionsArt from '../images/lesions-art.jpg'
import Follow from '../images/follow.jpg'

const AlbumArtVert = () => {

    return (
    <div className="aa-vert">
    <div className="album-promo">

    <div className="album-art"><a className="listen-link" href={"https://distrokid.com/hyperfollow/naideia/lesions"}><img src={LesionsArt} alt="Lesions Album Art" /></a></div>

    <div className="album-art"><a className="listen-link" href={"https://distrokid.com/hyperfollow/naideia/reflect"}><img src={ReflectArt} alt="Subterranean Album Art" /></a></div>

    <div className="album-art"><a className="listen-link" href={"https://distrokid.com/hyperfollow/naideia/subterranean"}><img src={SubterraneanArt} alt="Horizon Album Art" /></a></div>

  </div>

    <div className="album-promo">

    <div className="album-art"><a className="listen-link" href={"https://distrokid.com/hyperfollow/naideia/horizon"}><img src={HorizonArt} alt="Linked EP Album Art" /></a></div>

    <div className="album-art"><a className="listen-link" href={"https://distrokid.com/hyperfollow/naideia/linked-ep"}><img src={LinkedArt} alt="Linked EP Album Art" /></a></div>

    <div className="album-art"><a className="listen-link" href={"https://open.spotify.com/artist/1LRolJj9QDXXL6olDwZlOA?si=j0vtXUehRN27gWBZUuugkw"}><img className="promo" src={Follow} alt="Spotify Follow Image" /></a></div>

  </div>
  </div>
    )
}

export default AlbumArtVert;
